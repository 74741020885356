.OpenRequests {
   
    width: 85%;
    .OpenRequests_element {
        width: 100%;
        border-radius:15px;
        background-color: #86bdc370;
        margin-bottom: 20px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .OpenRequests_element_left {
            width: 50%;
            h1 {
                color: var(--secondary);
                font-weight: bold;
                font-size: 22px;
            }
            h2 {
                color: var(--secondary);
                margin-top: 8px;
                margin-bottom: 0;
                font-size: 17px;
                span {
                    color: #000;
                    font-weight: bold;
                }
            }
            p {
                margin-top: 25px;
                font-size: 14px;
                color:#121212;
            }
        }
        .OpenRequests_element_right{
            width: 40%;
            display: flex;
            flex-direction: column;
            .OpenRequests_element_right_btns{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                div,a{
                    width: 47%;
                    button{
                        padding: 7px;
                        border-radius: 30px;
                        background-color: var(--btn_primary);
                        font-size: 11px;
                        font-weight: bold;
                        width: 100%;
                        color:#fff; 
                        cursor: pointer;
                        &:hover{
                            background-color: var(--btn_hover);
                        }
                    }
                }
            }
            .OpenRequests_element_right_data{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                margin-top: 17px;
                div{
                   display: flex;
                   align-items: center;
                    width: 48%;
                    flex-wrap: wrap;
                    font-weight: bold;
                    margin-top: 5px;
                }
                p{
                   
                    font-size: 11px;
                    color:var(--secondary);
                 
                    
                }
                span{
                    color:#000;
                    font-weight: normal;
                    font-size: 11px;
                    font-weight: bold;
                    margin-left: 3px;
                }
            }
        }
    }
}

@media(max-width:992px){
    .OpenRequests {
        width: 100%;
        .OpenRequests_element {
            flex-wrap: wrap;
            .OpenRequests_element_left {
                width: 100%;
                h1 {
                    font-size: 15px;
                }
                h2 {
                    font-size: 15px;
                    span {
                      
                        font-weight: bold;
                    }
                }
               
            }
            .OpenRequests_element_right{
                width: 100%;
                .OpenRequests_element_right_btns{
                    order:2;
                    div{
                        margin-top: 20px;
                        width: 47%;
                       
                    }
                }
               
            }
        }
    }
    
}

@media(max-width:576px){
    .OpenRequests {
        width: 100%;
        .OpenRequests_element {
            flex-wrap: wrap;
            .OpenRequests_element_left {
                width: 100%;
                h1 {
                    font-size: 15px;
                }
                h2 {
                    font-size: 15px;
                    span {
                      
                        font-weight: bold;
                    }
                }
               
            }
            .OpenRequests_element_right{
                width: 100%;
                .OpenRequests_element_right_btns{
                    order:2;
                    div{
                        margin-top: 20px;
                        width: 47%;
                       
                    }
                }
               
            }
        }
    }
    
}