@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap");

:root {
    --primary: #fed049;
    --btn_primary: #fed049;
    --btn_hover: #e7bc3c;
    --secondary: #007580;
    --list_color_hover: #469ba3;
    --red: #e01b24;
    --grey: #fafafa;
    --light_secondary:#d9d9d9;
    --light_grey: #f2f2f2;
    --middle_grey:#dfdfdf;
    --hard_grey: gray;
    --black: #000000;
    --list_color: #86bdc3;
    --headline_1: 60px;
    --headline_2: 50px;
    --headline_3: 41.89px;
    --headline_4: 22px;
    --paragraph: 60px;
    --btn_disabled: #dbdbdb;
    --btn_reject: #e01b24;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: 0;
    border: 0;
    transition: 0.4s;
    font-family: "Open Sans", sans-serif;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    height: 100%;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.App {
    width: 100%;
    height: 100%;
    background-image: url("./assets/background_pattren.png");
    background-position: center;
    // background-repeat: repeat; 
    background-size: cover; 
    background-attachment: fixed;
    position: relative;
}
.app_overlay{
    // position: absolute;
    // top:0;
    // left:0;
    // right:0;
    // bottom: 0;
    // background-color: #ffffffb8;
}
.app_container {
    width: 94%;
    margin: auto;
}
.hideList{
    display: none !important;
}

@mixin flex_center{
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin flex_between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin flex_around{
    display: flex;
    justify-content: space-around;
    align-items: center;
}