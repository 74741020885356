.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 10px;
    .navbar_logo {
        img {
            width: 120px;
        }
    }
    .navbar_account {
        position: relative;
        width: 70px;
        height: 30px;
        border-radius: 50px;
        background-color: var(--light_secondary);
        color:var(--grey);
        .navbar_account_circle {
            position: absolute;
            top: 0;
            right:0;
            bottom: 0;
            background-color: var(--secondary);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;
        }
        .navbar_account_dropdown {
            position: absolute;
            z-index: 2;
            top: 40px;
            right: 0;
            background-color: var(--list_color);
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            padding: 10px 10px 0 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 250px;
            transition: .5s;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            .navbar_account_dropdown_data {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .navbar_account_dropdown_data_img {
                    width: 30%;
                    justify-self: flex-end;
                    img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                }
                .navbar_account_dropdown_data_personal {
                    width: 60%;
                    p {
                        font-size: 14px;
                     
                        &:first-child {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .navbar_account_dropdown_view {
                width: 100%;
                background-color: var(--secondary);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 3px 0;
                margin-top: 15px;
                border-radius: 30px;
                cursor: pointer;
                &:hover {
                    background-color: var(--list_color_hover);
                }
                a{
                    color:#fff;
                }
                
            }
            .navbar_account_dropdown_help {
                margin-top: 25px;
                text-align: left;
                width: 100%;
                cursor: pointer;
               
            }
            .navbar_account_dropdown_logOut{
                margin-top: 20px;
                border-top: 1px solid var(--grey);
                width: 100%;
                text-align: center;
                padding: 5px 0;
                cursor: pointer;
                
            }
            .navbar_account_dropdown_login{
                border-bottom: 1px solid var(--grey);
                width: 100%;
                text-align: center;
                cursor: pointer;
                padding-bottom: 10px;
                a{
                    color:var(--grey);
                }
            }
            .navbar_account_dropdown_signup{
                width: 100%;
                text-align: center;
                cursor: pointer;
                padding: 10px 0;
                a{
                    color:var(--grey);
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .navbar {
        .navbar_logo {
            img {
                width: 180px;
            }
        }
        .navbar_account {
            width: 75px;
            height: 40px;
            .navbar_account_circle {
                width: 40px;
                height: 40px;
            }
        }
    }
}
@media (max-width: 574px) {
    .navbar {
        .navbar_logo {
            img {
                width: 150px;
            }
        }
        .navbar_account {
            width: 60px;
            height: 35px;
            .navbar_account_circle {
                width: 35px;
                height: 35px;
            }
        }
    }
}

.hideAccount{
    display: none !important;
}
.moveCircle{
    right:100% !important;
    transform: translateX(100%) !important;
}