.Calendar{
    width: 100% !important;
    margin-top: 30px !important; 
}


.responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 9px !important; /* default to 10px */
  }
  
  @media (max-width: 1500px) {
    .responsive-calendar {
      font-size: 8px !important;
    }
  }
  
  @media (max-width: 1200px) {
    .responsive-calendar {
      font-size: 7px !important;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-calendar {
      font-size: 6px !important;
    }
  }
  
  /* Large screens */
  @media (min-width: 2500px) {
    .responsive-calendar {
      font-size: 12px !important;
    }
  }