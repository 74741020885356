.EditMentorPortfolio {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .EditMentorPortfolio_leftOptions {
        width: 20%;
        background-color: var(--secondary);
    }
    .EditMentorPortfolio_middleMain {
        width: 57%;
        display: flex;
        flex-direction: column;
        ._2Tabs{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            p{
                border-bottom:.1rem solid var(--primary);
                padding-bottom: 5px;
                cursor: pointer;
                &:nth-child(2){
                    margin-left: 40px;
                }
            }
           
        }
        .saveBTN{
            text-align: right;
        }
        .saveBTN button{
            padding: 15px 30px;
            background-color: #fecf49;
            border-radius: 30px;
            font-size: 22px;
            color:#fff;
            cursor: pointer;
        }
    }
    .EditMentorPortfolio_rightChat {
        width: 20%;
        background-color: var(--primary);
    }
}

@media (max-width: 768px) {
    .EditMentorPortfolio {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100%;
        .EditMentorPortfolio_leftOptions {
            width: 20%;
            background-color: var(--secondary);
            height: 100%;
            display: none;
        }
        .EditMentorPortfolio_middleMain {
            width: 100%;
           
            ._2Tabs{
                p{
                    font-size: 12px;
                }
            }
        }
        .EditMentorPortfolio_rightChat {
            width: 20%;
            background-color: var(--primary);
            height: 100%;
            display: none;
        }
    }
}
